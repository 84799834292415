import React, { Component } from 'react';
import { GoogleLogin } from 'react-google-login';
import UserService from '../../services/UserService';
import { clientId, signInScope } from '../../constants/googleLogin';
import { Logo, CalendarSync } from '../../components/images';
import { Loader } from '../../components/base';

import styles from "./Signin.module.scss";

class Signin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: ''
    }

    this.onSuccess = this.onSuccess.bind(this)
  }

  async onSuccess(response) {
    this.setState({ loading: true })

    const { code } = response

    // Login action
    let res = await UserService.signin({ code });

    if (res.error) {
      this.setState({ error: 'error', loading: false })
      return;
    }

    this.setState({ loading: false })
  }

  responseGoogle(res) {
    console.log(res)
  }

  render() {
    return (
      <div className={styles.signin}>
        <div className={styles.signinBox}>
          <Logo className={styles.logo} />
          <img src={CalendarSync} title="Sync calendar" alt="" className={styles.syncImage} />
          <h1>Calendar Integration</h1>
          <p className={styles.syncText}>Simply sync your calendar events to receive a summary of upcoming weather conditions on your notes.</p>
          {!this.state.loading && (
            <GoogleLogin
              clientId={clientId}
              buttonText="Sign in with Google"
              onSuccess={this.onSuccess}
              onFailure={this.responseGoogle}
              scope={signInScope}
              accessType="offline"
              responseType={'code'}
              prompt={"consent"}
              cookiePolicy={'single_host_origin'}
            />
          )}

          {this.state.loading && <Loader />}
          {this.state.error && <span>{this.state.error}</span>}
        </div>
      </div>
    );
  }
}

export default Signin