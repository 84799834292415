import CalendarServiceAPI from "../api/CalendarServiceAPI"
import { UPDATE_CALENDARS, SYNC_CALENDAR, SET_SYNC_STATUS } from "./types";

export const fetchCalendars = () => async dispatch => {
  let res = await CalendarServiceAPI.getCalendars();

  if (!res.success) {
    return dispatch({
      type: UPDATE_CALENDARS,
      payload: {
        list: [],
        error: res.error
      }
    })
  }

  dispatch({
    type: UPDATE_CALENDARS,
    payload: {
      list: res.data
    }
  })
}

export const syncCalendar = ({ id }) => async dispatch => {

  let res = await CalendarServiceAPI.syncCalendar({ id });


  if (!res.success) {
    return dispatch({
      type: SYNC_CALENDAR,
      payload: {
        id,
        error: res.error
      }
    })
  }

  dispatch({
    type: SYNC_CALENDAR,
    payload: { id }
  })
}

export const setSyncStatus = ({ id, status }) => async dispatch => {

  let res = await CalendarServiceAPI.setSyncStatus({ id, status });

  if (!res.success) {
    return dispatch({
      type: SET_SYNC_STATUS,
      payload: { id, error: res.error }
    })
  }

  dispatch({
    type: SET_SYNC_STATUS,
    payload: { id, status }
  })
}