import React from 'react';
import _ from 'lodash';

import styles from './CalendarsList.module.scss';
import { CalendarItem } from './CalendarItem';
import { Loader } from '../base'

const CalendarsList = React.memo(({ list, lastUpdated }) => {
  console.log('Calendar list rendered');
  if (_.isUndefined(list)) {
    return <Loader />
  }

  let calendarList = list.map(item => <CalendarItem {...item} />)
  return (
    <div className={styles.root}>
      <table>
        <thead>
          <tr>
            <th>Calendar Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {calendarList}
        </tbody>
      </table>
    </div>
  )
})

export { CalendarsList };