import React from 'react';
import { Route } from 'react-router-dom';
import { Logo } from '../../components/images';

import styles from './Base.module.scss'
import { Calendar } from '../calendar/Calendar';

const Base = React.memo(({ match }) => {
  const { url } = match;

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <Logo />
      </header>

      <div className={styles.frame}>
        <Route path={`${url}`} component={Calendar} />
      </div>
    </div>
  )
})

export { Base }