import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import _ from "lodash";

let cacheKey = "store";

// Store caching functions
const loadState = () => {
  try {
    const serializedState = localStorage.getItem(cacheKey);
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(cacheKey, serializedState);
  } catch (e) {
  }
};

// Initial the store
const initialState = loadState();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(thunk)
  )
);

store.subscribe(_.throttle(() => {
  // Select the data to save
  let storeState = store.getState();
  saveState({
    Auth: storeState.Auth
  });
}, 1000));

export default store;
