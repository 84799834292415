import { execute, POST } from "./Executer";
import Config from "../Config";

const URL = `${Config.api.url}`

/**
 * API - POST - Login
 * @param {Object} credentials - The user credentials
 * @async
 */
const signin = async ({ code }) =>
  await execute(POST, `${URL}signin`, { code }, { checkAccessToken: false, printErrorLogs: false });


export default {
  signin
}