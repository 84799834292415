import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import UserService from "../../services/UserService";

class AuthRoute extends Component {
  constructor(props) {
    super(props);

    this.renderPrivatePage = this.renderPrivatePage.bind(this)
  }

  renderPrivatePage(props) {
    // Check if user is authenticated
    if (UserService.isAuthenticated) {
      // Redirect back to home
      return (
        <Redirect
          to={{
            pathname: `/`,
            state: { from: props.location }
          }}
        />
      );
    }

    // Render the route
    let Component = this.props.component;
    return <Component {...props} />
  }

  render() {
    return (
      <Route
        exact={this.props.exact}
        path={this.props.path}
        render={this.renderPrivatePage}
      />
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  return {
    auth: Auth.token
  }
};

export default connect(mapStateToProps)(AuthRoute)