import React from 'react'
import './Loader.scss';

const Spinner = React.memo(() => {
  return (
    <div className="spinner">
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
})

export { Spinner }