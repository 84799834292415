import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import UserService from "../../services/UserService";

export default class PrivateRoute extends Component {
  constructor(props) {
    super(props);

    this.renderPrivatePage = this.renderPrivatePage.bind(this)
  }

  renderPrivatePage(props) {
    // Check if user is authenticated
    if (!UserService.isAuthenticated) {
      // Redirect back to home
      return (
        <Redirect
          to={{
            pathname: `/signin`,
            state: { from: props.location }
          }}
        />
      );
    }

    // Render the route
    let Component = this.props.component;
    return <Component {...props} />
  }

  render() {
    return (
      <Route
        exact={this.props.exact}
        path={this.props.path}
        render={this.renderPrivatePage}
      />
    );
  }
}
