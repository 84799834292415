import React from 'react'
import { Provider } from 'react-redux'
import store from '../../store'

import styles from './App.module.scss';

import UserService from '../../services/UserService';
import { Router } from '../router';

UserService.init()

const App = React.memo(() => {

  return (
    <Provider store={store}>
      <div className={styles.app}>
        <Router />
      </div>
    </Provider>
  );
})

export { App };