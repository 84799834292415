import _ from 'lodash';
import axios from "axios";
import Config from '../Config';
// import Utils from '../Utils';

const GET = "get";
const POST = "post";
const PUT = "put";
const DELETE = "delete";
const PATCH = "patch";

axios.defaults.headers.post["Content-Type"] = "application/json";

/**
 * Execute - The function who execute the all requests
 * @param  {string} action - HTTP method
 * @param  {string} url - The endpoint url
 * @param  {object} data={} - The request body
 * @param  {object} options={} - Custom options for execute action
 * @param  {int} retryAttempts=0 - The attempts number of retry
 */

const execute = async (action, url, data = {}, options = {}, retryAttempts = 0, networkRetryAttempts = 0) => {
  let error;
  let response;

  // Default options
  let defaultOptions = {
    printLog: false,
    isRefresh: false,
    printErrorLogs: true,
    checkAccessToken: true,
    maxNetworkRetryAttempts: Config.api.retryNetworkMaxAttempts
  };

  // Combine the default with the custom options
  options = Object.assign({}, defaultOptions, options);

  try {
    response = await axios[action](url, data);

    if (options.printLog) {
      console.log({ url, response, action, data });
    }

    let dataRes = !_.isUndefined(response.data.data) ? response.data.data : response.data;
    return { data: dataRes, code: response.data.code, success: true };
  } catch (e) {
    error = e;
  }

  if (options.printErrorLogs) {
    console.warn({ url, error, action, data });
  }

  return generateError({ url, error, action, data });
}

const generateError = (apiLog) => {
  let dataRes = apiLog.error.response;
  let code;
  if (!_.isObject(dataRes)) {
    dataRes = {};
    code = -1;
  } else {
    dataRes = dataRes.data;
    code = dataRes.code;
  }

  let res = { data: dataRes, code, error: apiLog.error, success: false }

  return res;
}


const setAccessToken = (accessToken) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
}

const removeAccessToken = () => {
  delete axios.defaults.headers.common.Authorization;
}

export { execute, setAccessToken, removeAccessToken, GET, POST, PUT, DELETE, PATCH };