import UserServiceAPI from "../api/UserServiceAPI";
import Store from "../store";
import { login, logout } from "../actions/UserServiceActions";
import * as Executer from '../api/Executer';

class UserService {
  init() {
    if (this.accessToken && this.isTokenExpired) {
      this.signout();
    }

    if (!this.accessToken) {
      return false;
    }


    // Add token
    Executer.setAccessToken(this.accessToken);
  }

  async signin({ code }) {
    let signinRes = await UserServiceAPI.signin({ code });

    if (signinRes.error) {
      return { error: signinRes.error };
    }

    // updateUser

    Executer.setAccessToken(signinRes.data.token);
    Store.dispatch(login(signinRes.data.token));

    return { success: true };
  }

  signout() {
    Store.dispatch(logout());
  }

  // ========== Auth methods =============
  get accessToken() {
    let { token } = Store.getState().Auth;
    return token && token;
  }

  get isTokenExpired() {
    let { tokenDetails } = Store.getState().Auth;
    if (tokenDetails && tokenDetails.exp) {
      return 1000 * tokenDetails.exp - Date.now() < 5000;
    }
    return true;
  }

  get isAuthenticated() {
    return !this.isTokenExpired;
  }
}

export default new UserService();