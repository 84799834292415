import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import styles from './CalendarItem.module.scss'
import { Button } from '../base/Button';
import { CALENDAR_STATUS, CALENDAR_MINIMUM_ACCESS_ROLE } from '../../constants/calendars';
import { Spinner } from '../base';
import { syncCalendar, setSyncStatus } from '../../actions/CalendarsActions';
import { formatTime } from '../../Utils';

// const CalendarItemInner = React.memo(({ id, summary, accessRole, status = false, primary = false, syncCalendar }) => {


class CalendarItemInner extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.syncCalendar = this.syncCalendar.bind(this)
    this.pauseSync = this.pauseSync.bind(this)
    this.resumeSync = this.resumeSync.bind(this)
  }

  async syncCalendar() {
    this.setState({ loading: true });

    await this.props.syncCalendar();

    this.setState({ loading: false });
  }

  async pauseSync() {
    this.setState({ loading: true });
    await this.props.setSyncStatus({ status: CALENDAR_STATUS.PAUSED });
    this.setState({ loading: false });
  }

  async resumeSync() {
    this.setState({ loading: true });
    await this.props.setSyncStatus({ status: CALENDAR_STATUS.SYNCED });
    this.setState({ loading: false });
  }

  actions() {
    let { status, accessRole } = this.props;

    if (this.state.loading) {
      return (<Spinner />)
    }

    if (!CALENDAR_MINIMUM_ACCESS_ROLE.includes(accessRole)) {
      return <span className={styles.error}>Not allowed (low access role)</span>
    }

    if (status === CALENDAR_STATUS.SYNCED) {
      return (
        <Button type="danger" className={styles.button} onClick={this.pauseSync}>
          Pause
        </Button>
      )
    }

    if (status === CALENDAR_STATUS.FAIL_TO_SYNC) {
      return (<h2>Fail to sync</h2>)
    }

    if (status === CALENDAR_STATUS.PAUSED) {
      return (
        <Button type="primary" className={styles.button} onClick={this.resumeSync}>
          Resume Sync
        </Button>
      )
    }

    return (
      <Button className={styles.button} onClick={this.syncCalendar}>
        Start sync
      </Button>
    )
  }

  calendarSyncTimes() {
    let { status, syncedAt = Date.now(), lastSynced } = this.props;

    if (!status) {
      return null
    }

    let lastSyncedText = lastSynced ? formatTime(lastSynced) : 'Never';

    return (
      <div className={styles.syncTimes}>
        Synced at: {formatTime(syncedAt)} |
        Last synced: {lastSyncedText}
      </div>
    )
  }

  syncStatus() {
    let { status } = this.props;

    if (!status || status !== CALENDAR_STATUS.SYNCED) {
      return null
    }

    return (
      <span className={styles.syncStatus}>
        - <span>Synced</span>
      </span>
    )
  }

  render() {
    let { id, summary, primary = false } = this.props;
    return (
      <tr className={styles.root} key={id}>
        <td>
          {summary} {primary && "( Primary )"} {this.syncStatus()} <br />
          {this.calendarSyncTimes()}
        </td>
        <td className={styles.actionsCell}>
          {this.actions()}
        </td>
      </tr>
    )
  }
}

const mapDispatchToProps = (dispatch, { id }) => {
  return {
    syncCalendar: () => dispatch(syncCalendar({ id })),
    setSyncStatus: ({ status }) => dispatch(setSyncStatus({ id, status }))
  }
};

const CalendarItem = connect(() => ({}), mapDispatchToProps)(CalendarItemInner);

export { CalendarItem };