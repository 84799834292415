import React from 'react';
import classnames from 'classnames';
import styles from './Button.module.scss'
import _ from 'lodash';

const buttonTypes = {
  danger: styles.danger,
  primary: styles.primary,
}

const Button = React.memo(({ children, onClick = () => { }, disabled = false, className, type }) => {

  let typeStyle = type && !_.isUndefined(buttonTypes[type]) && buttonTypes[type];

  return (
    <button onClick={onClick} disabled={disabled} className={classnames(styles.root, className, typeStyle)}>
      {children}
    </button>
  )
})

export { Button };