import { AUTH_LOGIN, AUTH_LOGOUT } from "./types";
import * as Executer from '../api/Executer';

export const login = (token) => dispatch => {
  dispatch({
    type: AUTH_LOGIN,
    payload: { token }
  });
}

export const logout = () => async dispatch => {
  Executer.removeAccessToken();
  dispatch({ type: AUTH_LOGOUT });
}