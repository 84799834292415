
import { execute, GET, POST, PATCH } from "./Executer";
import Config from "../Config";

const URL = `${Config.api.url}api/`

/**
 * API - GET - all calenders
 * @async
 */
const getCalendars = async () =>
  await execute(GET, `${URL}calenders`);

/**
* API - POST - Start sync calender
* @async
*/
const syncCalendar = async ({ id }) =>
  await execute(POST, `${URL}calenders`, { id });

/**
* API - PATCH - Set calendar sync status
* @async
*/
const setSyncStatus = async ({ id, status }) =>
  await execute(PATCH, `${URL}calenders`, { id, status });


export default {
  getCalendars,
  syncCalendar,
  setSyncStatus
}