import React from 'react'
import './Loader.scss';
import { Spinner } from './Spinner';

const Loader = React.memo(() => {
  return (
    <div className="spinner-wrapper">
      <Spinner />
      <div className="label">Loading</div>
    </div>
  )
})

export { Loader }