import _ from 'lodash';
import { UPDATE_CALENDARS, SYNC_CALENDAR, SET_SYNC_STATUS } from '../actions/types';
import { CALENDAR_STATUS } from '../constants/calendars';

const initialState = {};

const updateCalendars = (state, { list, error, lastUpdated = Date.now() }) => {
  let calendarsList = {
    list,
    lastUpdated
  }

  if (error) {
    calendarsList.error = error
  }

  return Object.assign({}, state, { calendarsList, lastUpdated: Date.now() })
}

const syncCalendar = (state, { id }) => {
  let { calendarsList } = state;

  let calendar = calendarsList.list.find(item => item.id === id);

  if (calendar) {
    calendar.status = CALENDAR_STATUS.SYNCED;
    calendarsList.lastUpdated = Date.now();
  }

  return Object.assign({}, state, { calendarsList, lastUpdated: Date.now() })
}


const setSyncStatus = (state, { id, status }) => {

  let { calendarsList } = state;

  let calendar = calendarsList.list.find(item => item.id === id);

  if (calendar) {
    calendar.status = status;
    calendarsList.lastUpdated = Date.now();
  }

  return Object.assign({}, state, { calendarsList, lastUpdated: Date.now() })
}


const actionsRepo = {
  [UPDATE_CALENDARS]: updateCalendars,
  [SYNC_CALENDAR]: syncCalendar,
  [SET_SYNC_STATUS]: setSyncStatus,
}

export default function (state = initialState, { type, payload }) {
  if (!_.isUndefined(actionsRepo[type])) {
    return actionsRepo[type](state, payload)
  }

  return state;
}
