import React from 'react';
import { connect } from 'react-redux';

import styles from './Calendar.module.scss';
import { CalendarsList } from '../../components/CalendarsList/CalendarsList';
import { fetchCalendars } from '../../actions/CalendarsActions';

const CalendarInner = React.memo(({ init, calendars }) => {
  React.useEffect(() => {
    init();
  }, []);

  return (
    <div className={styles.root}>
      <h1>Calendars</h1>
      <h3>Select calendars to sync</h3>
      <CalendarsList {...calendars} />
    </div>
  )
})

const mapStateToProps = (state) => ({
  calendars: state.calendars.calendarsList,
  calendarsLastUpdated: state.calendars.lastUpdated
})

const mapDispatchToProps = (dispatch) => ({
  init: () => {
    dispatch(fetchCalendars())
  }
});

const Calendar = connect(mapStateToProps, mapDispatchToProps)(CalendarInner);

export { Calendar }