import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import AuthRoute from "./AuthRoute";

// Pages
import Signin from "../../pages/auth/Signin";
import { Base } from "../../pages/base";
// import Frame from "../../pages/base/Frame";

const Router = React.memo(() => {
  return (
    <BrowserRouter>
      <Switch>
        {/* Auth */}
        <AuthRoute path="/signin" exact component={Signin} />

        {/* Private Paths */}
        <PrivateRoute path="/" component={Base} />
      </Switch>
    </BrowserRouter>
  );
})

export { Router };